@import '~antd/dist/antd.css';
@import "@ant-design/flowchart/dist/index.css";

html,
body,
#root {
	min-width: 1600px;
}

.clearfix:after {
	content: "";
	display: block;
	clear: both;
}

.clearfix {
	zoom: 1;
}


.loginpost {
	width: 1200px;
	height: 720px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	/* background-color: #fff; */
	/* padding         : 70px; */
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.loginpost .right {
	width: 460px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	position: relative;
	z-index: 8;
	border-radius: 0 25px 25px 0;
}

.loginpost .left {
	width: calc(100% - 460px);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	background-color: rgba(32, 119, 237, 1);
	position: relative;
	z-index: 2;
	box-sizing: border-box;
	padding-left: 50px;
	border-radius: 25px 0 0 25px;
}

.loginpost .left .p {
	font-size: 28px;
	color: #fff;
	margin-bottom: 15px;
}

.loginpost .left .p2 {
	font-size: 22px;
	color: #fff;
	/* margin-bottom: 15px; */
}

.loginpost .footer {
	width: 100%;
	position: absolute;
	bottom: -50px;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.4);
	z-index: 999;
}

.loginpost .footer a {
	color: inherit;
}

#normal_login {
	width: 360px;
}

.loginpost .head {
	width: 100%;
	text-align: center;
	line-height: 30px;
	margin-bottom: 60px;
	font-size: 30px;
	color: #1a6bd9;
	/* font-weight  : bold; */
}

.loginpost .input {
	height: 60px;
	font-size: 20px;
}

.loginpost .input .ant-input {
	font-size: 18px;
}

.loginpost .login-form-button {
	width: 100%;
	height: 60px;
	background-color: #1a6bd9;
	color: #fff;
	font-size: 22px;
	border: 0;
	border-radius: 8px;
}

.site-form-item-icon {
	color: #ddd;
}

.bj {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 3;
}

.simple-verify .verify-bar .icon {
	height: 43px !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: auto 100% !important;
}

.sellist {
	width: 100%;
	margin-bottom: 16px;
}

.sellist .input,
.sellist .select,
.sellist .but {
	/* height: 40px; */
	font-size: 14px;
}

.table {
	margin-bottom: 25px;
}

.page {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page2 {
	justify-content: flex-end;
}

.page3 {
	justify-content: space-between;
}

.page.pageb20 {
	margin-bottom: 20px;
}

.pageheader {
	color: #fff;
}

.logo {
	float: left;
	width: 250px;
	height: 100%;
	margin: 0;
	/* background: rgba(255, 255, 255, 0.3); */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	color: #fff;
}


.site-layout-background {
	background: #fff;
}

.ant-badge-count {
	box-shadow: none;
}

.site-input-split {
	background-color: #fff !important;
}

.site-input-right {
	border-left-width: 0;
}

.site-input-right:hover,
.site-input-right:focus {
	border-left-width: 1px;
}

.ant-input-rtl.site-input-right {
	border-right-width: 0;
}

.ant-input-rtl.site-input-right:hover,
.ant-input-rtl.site-input-right:focus {
	border-right-width: 1px;
}

.pubtitle {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 0 15px;
	background-color: #F3F6F9;
}

.pubtitle.mb20 {
	margin-bottom: 20px;
}

.pubtitle .l {
	font-size: 16px;
	color: #444;
	font-weight: bold;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.pubtitle .l.hongbang {
	color: #b50102;
}

.pubtitle .l .num {
	color: #0084ff;
	font-size: 16px;
	font-weight: bold;
	margin-left: 4px;
}

.pubtitle.pubtitle2 {
	height: auto;
	margin-bottom: 24px;
	background-color: #fff;
	padding: 0;
}

.xmtable {
	width: 100%;
	margin-bottom: 40px;
}

.xmtable td {
	/* border:1px solid #ddd; */
	padding: 10px 15px;
}

.xmtable td.title {
	width: 150px;
	text-align: left;
}

.xmtable4 td.title+td {
	width: 600px;
}

.xmtable4 td.title3 {
	width: 230px;
	text-align: left;
}

.xmtable4 td.title3+td {
	width: 520px;
}

.xmtable td.title2 {
	color: #2c83bf;
	border-right: 0;
}

.xmtable td.title2+td {
	border-left: 0;
}

.xmtable td.nopd {
	padding: 0;
}

.xmtable tr:nth-child(odd) {
	background-color: #fafafa;
}

.tjtable {
	width: 100%;
}

.tjtable tr:first-child td {
	background-color: #fafafa;
}

.tjtable td {
	border-bottom: 1px solid #f0f0f0;
	padding: 10px 15px;
	text-align: center;
}

.tjtable tr:hover td {
	background-color: #fafafa;
}

.xmtable2 {
	width: 100%;
}

.xmtable2 tr:first-child td {
	background-color: #fafafa;
}

.xmtable2 td {
	border: 1px solid #f0f0f0;
	padding: 10px 15px;
	text-align: center;
}

.xmtable2 tr:hover td {
	background-color: #fafafa;
}

.xmtable2 td .allcheck {
	color: #444;
}

.xmtable3 {
	width: 300px;
	background-color: #fff;
}

.xmtable3 td {
	border: 1px solid #f0f0f0;
	padding: 5px 10px;
	text-align: center;
}

.xmtable3 tr:nth-child(even) {
	background-color: #fafafa;
}

.xmtable3 tr:hover td {
	background-color: #fafafa;
}

.xmtable3 td .allcheck {
	color: #444;
}

.xmtable4 {
	width: 100%;
	margin-bottom: 20px;
	/* background-color: rgb(243,246,249); */
}

.xmtable4.mb40 {
	margin-bottom: 40px;
}

.xmtable4 td {
	/* border:1px solid #ddd; */
	padding: 10px 15px;
}

.tyc {
	color: inherit;
}

.tyc:hover {
	text-decoration: underline;
}

.xmtable4 td.title {
	width: 150px;
	text-align: left;
}

.xmtable4 td.title+td {
	width: 600px;
}

.xmtable4 td.title2 {
	color: #2c83bf;
	border-right: 0;
}

.xmtable4 td.title2+td {
	border-left: 0;
}

.xmtable4 td.nopd {
	padding: 0;
}

.xmtable4 tr:nth-child(even) {
	background-color: #fafafa;
}

.ckid_item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.ckid_item.ing {
	background-color: #faad14;
	border: 1px solid #faad14;
}

.ckid_item.finish {
	background-color: #14b909;
	border: 1px solid #14b909;
}

.ckid_item .cha {
	color: #f00;
	cursor: pointer;
	margin-left: 5px;
}

.ckid_item.i1 {
	min-width: 100px;
	height: 35px;
	background-color: #E4F0FB;
	color: #1890FF;
	border-radius: 50px;
	border: 0;
	padding: 3px 15px;
}

.ckid_item.i2 {
	min-width: 100px;
	height: 35px;
	background-color: #FBEEEE;
	color: #EA6C6C;
	border-radius: 50px;
	border: 0;
	padding: 3px 15px;
}

.mplist {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 20px 0;
	box-sizing: border-box;
}

.mplist .item {
	width: calc((100% - 30px)/2);
	margin-bottom: 30px;
	margin-right: 30px;
	box-shadow: 0 0 15px #ddd;
}

.mplist .item:nth-child(2n+2) {
	margin-right: 0;
}

.mplist .item .top {
	width: 100%;
	height: 43px;
	padding: 0 20px;
	box-sizing: border-box;
	background-color: #0E6FCA;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.mplist .item .top .edit {
	color: #fff;
	font-size: 16px;
}

.mplist .item .neirong {
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	background-color: #fff;
}

.mplist .item .neirong .futitle {
	/* margin-left: 10px; */
	font-size: 14px;
	color: inherit;
}

.mplist .item .neirong .p1 {
	font-size: 18px;
	/* margin-bottom: 10px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mplist .item .neirong .p1 .zhiwei {
	font-size: 14px;
}

.mplist .item .neirong .p2 {
	font-size: 16px;
	color: #3292b0;
	/* margin-bottom: 10px; */
}

.mplist .item .neirong .p2+.futitle {
	color: #3292b0;
}

.mplist .item .neirong .p2:hover {
	text-decoration: underline;
}

.mplist .item .neirong .p3 {
	font-size: 14px;
	/* margin-bottom: 10px; */
}

.mplist .item .neirong .p3:last-child {
	margin-bottom: 0;
}

.mplist .item .neirong .p3 .img {
	margin-right: 10px;
}

.checktel {
	color: #f00;
}

.checktel a {
	color: #f00;
}

.ltlist {
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
	box-sizing: border-box;
	background-color: #fff;
	padding: 20px 0;
	padding-bottom: 0;
	margin-bottom: 20px;
}

.ltlist .item {
	width: 100%;

	margin-bottom: 20px;
}

.ltlist .item.i1 {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ltlist .item.i2 {
	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
	justify-content: flex-start;
}

.ltlist .item .l {
	flex-shrink: 0;
}

.ltlist .item.i1 .l {
	margin-right: 20px;
}

.ltlist .item.i2 .l {
	margin-left: 20px;
}

.ltlist .item .neirong {
	/* min-width: min-content;
	max-width: 600px; */
	min-height: 75px;
	max-width: 100%;
	box-sizing: border-box;
	padding: 5px 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.ltlist .item.i1 .neirong {
	background-color: #fafafa;
	border: 1px solid #ddd;
}

.ltlist .item.i2 .neirong {
	background-color: rgb(177, 228, 129);
	border: 1px solid #F3F6F9;
}

.ltlist .item .neirong .p1 {
	width: 100%;
	font-size: 14px;
	color: #888;
	margin-bottom: 5px;
}

.ltlist .item.i1 .neirong .p1 .name {
	/* color: #0084ff; */
}

.ltlist .item .neirong .p1 .del {
	cursor: pointer;
	color: #f00;
}

.ltlist .item .neirong .p2 {
	width: 100%;
	font-size: 14px;
	color: #333;
	margin-bottom: 10px;
}

.ltlist .item .neirong .p2 img,
.ltlist .item .neirong .p2 video {
	max-width: 100%;
}

.shuru {
	width: 100%;
	box-sizing: border-box;
	padding: 20px 0;
	padding-top: 0;
}

.hongse,
.hongse:hover,
.hongse:focus {
	color: #f00;
}

.hongse2,
.hongse2:hover,
.hongse2:focus {
	color: #f00;
	cursor: pointer;
}

.tongji {
	width: 100%;
	margin-bottom: 40px;
}

.tongji .tj_title {
	width: 100%;
	height: 50px;
	background-color: #fafafa;
	padding: 0 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.tongji .neirong {
	background-color: #f3f3f3;
	width: 100%;
	padding: 40px 0;
	display: flex;
	justify-content: space-around;
	align-items: stretch;
	flex-wrap: wrap;
}

.tongji .neirong .item {
	/* width: calc(100% / 10); */
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.tongji .neirong .item .p1 {
	font-size: 14px;
	color: #666;
}

.tongji .neirong .item .p2 {
	font-size: 30px;
	color: #666;
	font-weight: bold;
}

.tongji .neirong .item .p2 .a1 {
	font-size: 30px;
	color: #247ed1;
	font-weight: bold;
}

.tongji .neirong .item .p2 .a2 {
	font-size: 20px;
	color: #aaa;
	font-weight: normal;
}

.tongji .neirong .item .p3 {
	font-size: 14px;
	color: #0eb139;
}

.tongji .neirong .item .p4 {
	/* padding-top: 20px; */
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.tongji .neirong .item .p4 .item2 {
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	white-space: nowrap;
	margin-bottom: 10px;
}

.tongji .neirong .item .p4 .item2.item2-2 {
	justify-content: center;
}

.tongji .neirong .item .p4 .item2 .num {
	color: #0eb139;
}

.marb40 {
	margin-bottom: 40px;
}

.download {
	color: #444;
}

.download .pic {
	color: #2c83bf;
}

.treetable {
	width: 100%;
}

.treetable th {
	height: 50px;
	background-color: #fafafa;
	color: #2c83bf;
	font-size: 14px;
	text-align: left;
	line-height: 50px;
	padding: 0;
	text-align: center;
}

.treetable td {
	height: 50px;
	text-align: center;
}

.treetable .left {
	text-align: left;
}

.treetable .jiantou {
	color: #3A62D7;
	font-size: 20px;
	margin-right: 20px;
	transform: rotateY(180deg);
	color: #ccc;
}

.shangchuan {
	width: 100%;
	padding: 0 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
}

.ant-layout-header {
	background-color: #0E6FCA;
	padding-left: 0;
}

.bluemenu {
	background-color: #0E6FCA;
}

.leftmenu .ant-menu-item-selected {
	background-color: #101010 !important;
}

.leftmenu .ant-menu-item {
	margin: 0 !important;
	height: 60px;
}

.leftmenu .ant-menu-submenu-title {
	margin: 0 !important;
	height: 60px !important;
}

.tableview {
	color: rgba(0, 0, 0, 0.85);
}

.tableview .pic {
	color: #1890ff;
	margin-right: 10px;
}

.ant-form-item-required {
	color: #ff4d4f !important;
}

.zhengchang .ant-form-item-required {
	color: rgba(0, 0, 0, 0.85) !important;
}

.addbut {
	color: #40a9ff;
	border-color: #40a9ff;
	background: #F7FBFF;
}

.tjbox {
	width: 100%;
	box-sizing: border-box;
	background-color: #fff;
	/* margin-bottom: 24px; */
}

.qx_name {
	font-size: 20px;
	color: #101010;
}

.qx_name2 {
	font-size: 14px;
	color: #aaa;
	margin-bottom: 24px;
}

.qybase {
	width: 100%;
	margin-bottom: 20px;
}

.qybase .p1 {
	font-size: 18px;
	color: #000;
	font-weight: bold;
}

.qybase>.p2 {
	width: 100%;
	padding: 10px 0;
	margin-bottom: 10px;
}

.qybase>.p3 {
	width: 100%;
	background-color: #f5fbff;
	padding: 15px;
}

.qybase>.p3 .item {
	font-size: 14px;
	color: #333;
	line-height: 30px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.qybase>.p3 .item .p {
	font-size: 14px;
	color: #333;
	line-height: 30px;
	width: 50%;
}

.chuantou {
	width: 100%;
	margin-top: 20px;
	border: 1px solid #e4eef6;
	margin-bottom: 40px;
}

.chuantou .p1 {
	width: 100%;
	height: 40px;
	box-sizing: border-box;
	padding: 0 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #f0f7fc;
	border-bottom: 1px solid #e4eef6;
	font-size: 14px;
	color: #333;
}

.chuantou .p2 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chuantou .p2 img {
	max-width: 100%;
}

.pubtable {
	width: 100%;
	border: 1px solid #e4eef6;
	margin-top: 20px;
	margin-bottom: 40px;
}

.pubtable td {
	padding: 10px 5px;
	min-height: 40px;
	box-sizing: border-box;
	text-align: center;
}

.pubtable td.left {
	text-align: left;
}

.pubtable tr:first-child {
	background-color: #f0f7fc;
}

.mpstyle {
	background-color: #F7F8FA;
	box-sizing: border-box;
	border: 1px dashed #DEE5FB;
	padding-top: 10px;
	margin-bottom: 10px;
}

.qystyle {
	background-color: #f7fbff;
	box-sizing: border-box;
	border: 1px dashed #40a9ff;
	padding-top: 10px;
	margin-bottom: 10px;
}

.searchlist {
	width: calc(100% - 100px);
}

.searchlist .item>* {
	width: 100%;
}

.fullwidth label {
	width: 100%;
}

.fullwidth label .zy {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.hb .ant-menu-title-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.butr .tubiao {
	/* display: block; */
	color: #fff;
	padding: 10px;
}

.butr .tubiao .anticon {
	color: #fff;
}

.viewlink {
	margin-bottom: 15px;
}

.viewlink a {
	color: #222;
}

.pubbox {
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	background-color: #fff;
	margin-bottom: 20px;
}

.title_p {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 18px;
}

.title_p .l {
	font-size: 18px;
	color: #101010;
}

.title_p .r {}

.b1 {
	padding-bottom: 0;
}

.b1 .list {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
}

.b1 .list .item {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-right: 40px;
	margin-bottom: 20px;
}

.b1 .list .item .p1 {
	width: 50px;
	height: 50px;
	border-radius: 15px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}

.b1 .list .item.i1 .p1 {
	background-color: #1890FF;
}

.b1 .list .item.i2 .p1 {
	background-color: #13C2C2;
}

.b1 .list .item.i3 .p1 {
	background-color: #FA8C16;
}

.b1 .list .item.i4 .p1 {
	background-color: #EB2F96;
}

.b1 .list .item.i5 .p1 {
	background-color: #722ED1;
}

.b1 .list .item.i6 .p1 {
	background-color: #F5222D;
}

.b1 .list .item.add {
	cursor: pointer;
}

.b1 .list .item.add .p1 {
	color: #1890FF;
	border: 1px dashed #1890FF;
	box-sizing: border-box;
}

.b1 .list .item .p2 {
	font-size: 14px;
	color: #101010;
	text-align: center;
}

.boxlist {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
}

.boxlist .item {
	width: calc((100% - 20px)/2);
	margin-bottom: 20px;
	margin-right: 20px;
}

.boxlist .item:nth-child(2n+2) {
	margin-right: 0;
}

.cyl {
	width: 100%;
	height: calc(100% - 66px);
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}

.cyl .ptitle {
	width: 100%;
	padding-left: 10px;
	box-sizing: border-box;
	font-size: 16px;
	color: #000;
	position: relative;
	margin-bottom: 20px;
}

.cyl .ptitle::before {
	content: '';
	width: 4px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #0084ff;
}

.cyl>.l {
	width: 500px;
	margin-right: 20px;
	overflow-y: auto;
}

/* .cyl > .l > .p1{
	width: 100%;
	height: 60px;
	margin-bottom: 20px;
	background-color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
}
.cyl > .l > .p1 .title{
	color: #0084ff;
} */
.cyl>.l>.p2 {
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	height: 100%;
	background-color: #fff;
}

.cyl>.l>.p2 .list {
	width: 100%;
	height: calc(100% - 47px);
	overflow-y: auto;
}

.cyl>.l>.p2 .list>.item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	margin-bottom: 20px;
}

.cyl>.l>.p2 .list>.item:last-child {
	margin-bottom: 0;
}

:root {
	--cyl-color1: rgb(78, 176, 249);
	--cyl-color2: rgb(120, 198, 122);
	--cyl-color3: rgb(141, 130, 198);
}

.cyl>.l>.p2 .list>.item .l {
	width: 40px;
	padding: 20px 5px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #fff;
	font-size: 14px;
	margin-right: 20px;
}

/* 产业链颜色 */
.cyl>.l>.p2 .list>.item:nth-child(3n+1) .l {
	background-color: var(--cyl-color1)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+2) .l {
	background-color: var(--cyl-color2)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+3) .l {
	background-color: var(--cyl-color3)
}

.cyl>.l>.p2 .list>.item .r {
	width: calc(100% - 60px);
	background-color: rgb(248, 248, 248);
	box-sizing: border-box;
	padding: 20px;
	padding-bottom: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}

.cyl>.l>.p2 .list>.item .r>.item {
	width: calc((100% - 20px)/2);
	margin-bottom: 20px;
	margin-right: 20px;
}

.cyl>.l>.p2 .list>.item .r>.item:nth-child(2n+2) {
	margin-right: 0;
}

.cyl>.l>.p2 .list>.item:nth-child(3n+1) .r>.item {
	border: 1px solid var(--cyl-color1)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+2) .r>.item {
	border: 1px solid var(--cyl-color2)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+3) .r>.item {
	border: 1px solid var(--cyl-color3)
}

.cyl>.l>.p2 .list>.item .r>.item>.p1 {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	box-sizing: border-box;
	padding: 0 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
	color: #fff;
}

.cyl>.l>.p2 .list>.item:nth-child(3n+1) .r>.item>.p1 {
	background-color: var(--cyl-color1)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+2) .r>.item>.p1 {
	background-color: var(--cyl-color2)
}

.cyl>.l>.p2 .list>.item:nth-child(3n+3) .r>.item>.p1 {
	background-color: var(--cyl-color3)
}

.cyl>.l>.p2 .list>.item .r>.item>.p2 {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	box-sizing: border-box;
	padding: 0 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
	color: #000;
}

.cyl>.r {
	width: calc(100% - 520px);
	background-color: #fff;
	box-sizing: border-box;
	padding: 20px;
}

.cyl>.r .neirong {
	width: 100%;
	height: calc(100% - 47px);
	overflow-y: auto;
}

.selcyl {
	width: 100%;
	background-color: #fff;
	margin-bottom: 20px;
	padding: 0 20px;
	box-sizing: border-box;
}

.selcyl .ant-tabs-nav {
	margin-bottom: 0;
}

.wenduji {
	width: 400px;
	height: 20px;
	border-radius: 20px;
	background-color: #eee;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	padding: 0 5px;
}

.wenduji::before {
	content: '';
	background-color: inherit;
	position: absolute;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.wenduji .bfb {
	width: 0;
	height: 10px;
	border-radius: 20px;
	position: relative;
	z-index: 5;
}

.wenduji .bfb.s1 {
	background-color: #0084ff;
}

.wenduji .bfb.s2 {
	background-color: #ff8c00;
}

.wenduji .bfb.s3 {
	background-color: #f00;
}

.wenduji .bfb::before {
	content: '';
	background-color: inherit;
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.headcenter .ant-upload-picture-card-wrapper {
	display: flex;
	justify-content: center;
}

.headcenter .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
	margin: 0;
}

.yhqx {
	width: 100%;
}

.yhqx .item {
	width: 100%;
	margin-bottom: 20px;
}

.yhqx .item:last-child {
	margin-bottom: 0;
}

.yhqx .item .gongsi {
	font-size: 18px;
}

.yhqx .item .bumen {
	padding-left: 25px;
	font-size: 16px;
	margin-bottom: 5px;
}

.yhqx .item .qxlist {
	padding-left: 25px;
	font-size: 14px;
	margin-bottom: 5px;
}

.newsview {
	width: 100%;
	padding: 25px;
	box-sizing: border-box;
	background-color: #fafafa;
}

.newsview .p1 {
	width: 100%;
	font-weight: bold;
	color: #000;
	font-size: 24px;
	margin-bottom: 20px;
	text-align: center;
}

.newsview .p2 {
	font-size: 16px;
	color: #aaa;
	text-align: center;
	margin-bottom: 15px;
}

.newsview .p3 {
	font-size: 16px;
	color: #aaa;
	text-align: center;
	margin-bottom: 15px;
}

.newsview .p4 {
	width: 100%;
	font-size: 16px;
	color: #333;
	line-height: 30px;
}

.newsview .p4 p {
	margin-bottom: 0;
}

.newsview .p4 img,
.newsview .p4 video {
	max-width: 100%;
}

.xmjj_table {
	width: 100%;
}

.xmjj_table img,
.xmjj_table video {
	max-width: 100%;
}

.bgkeyword {
	background-color: yellow;
}

.guanzhu.hover {
	color: #c4c40c;
}

.diqueidt {
	font-size: 16px;
}

#editor {
	/* position: relative; */
}

#editor .at {
	width: 200px;
	height: 32px;
	position: absolute;
	/* top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto; */
	z-index: 99999;
}

.juzhong {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.picItem {
	position: relative;
}

.picItem .hongse {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 999;
}

.picupload .ant-upload.ant-upload-select-picture-card {
	margin: 0 !important;
}

.man {
	color: #0084ff;
}

.woman {
	color: #EA6C6C
}

.huise {
	background-color: #999;
	border: 1px solid #999
}

.huise:hover,
.huise:focus {
	background-color: #999;
	border: 1px solid #999
}

.huangse {
	background-color: #F09611;
	border: 1px solid #F09611
}

.huangse:hover,
.huangse:focus {
	background-color: #F09611;
	border: 1px solid #F09611
}

.lvse {
	background-color: #03C05D;
	border: 1px solid #03C05D
}

.lvse:hover,
.lvse:focus {
	background-color: #03C05D;
	border: 1px solid #03C05D
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	line-height: normal;
}


.scrollStyle ::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 4px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.scrollStyle ::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
	background: #ccc;
}

.scrollStyle ::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
	border-radius: 10px;
	background: #ededed;
}

.trGrey td a {
	color: rgba(0, 0, 0, 0.85);
}

.biaoqian {
	line-height: 20px;
	padding: 0 10px;
	border-radius: 35px;
	color: #fff;
}

.biaoqian.yx {
	background-color: #ff9f18;
}

.biaoqian.jj {
	background-color: #c72918;
}


.phbtitle {
	width: calc(100% - 20px);
	margin: 0 auto;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	position: relative;
	box-sizing: border-box;
	padding: 0 20px;
}

.phbtitle::before {
	content: '';
	width: 10px;
	height: 13px;
	position: absolute;
	top: 0;
	left: -10px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.phbtitle::after {
	content: '';
	width: 10px;
	height: 13px;
	position: absolute;
	top: 0;
	right: -10px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.phbtitle.hongbang {
	background-color: #b50102;
	color: #fff;
}

.phbtitle.hongbang::before {
	background-image: url(./images/hongl.png);
}

.phbtitle.hongbang::after {
	background-image: url(./images/hongr.png);
}

.phbtitle.heibang {
	background-color: #222;
	color: #fff;
}

.phbtitle.heibang::before {
	background-image: url(./images/heil.png);
}

.phbtitle.heibang::after {
	background-image: url(./images/heir.png);
}

.color_box {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid #ddd;
}

.sel_color {
	width: 15px;
	height: 15px;
	box-sizing: border-box;
}

.tagedit {
	cursor: pointer;
}

.tagcolor {
	color: #FA8C16;
}

.gjnr img,
.gjnr embed,
.gjnr video {
	max-width: 100%;
}

.okrlist {
	width: 100%;
	margin-bottom: 20px;
}

.okrlist .item {
	width: 100%;
	background-color: #fff;
	box-sizing: border-box;
	padding: 10px 20px;
	padding-bottom: 20px;
	margin-bottom: 10px;
}

.okrlist .item>table {
	width: 100%;
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
}

.okrlist .item>table .p1 {
	font-size: 14px;
	color: #aaa;
}

.okrlist .item>table .p1 .title {
	padding-left: 0;
}

.okrlist .item>table .p1 .title .bm {
	font-size: 12px;
	color: #1890FF;
}

.okrlist .item>table .p1 .title .dq {
	font-size: 12px;
	color: #aaa;
	cursor: pointer;
}

.okrlist .item>table td {
	padding: 10px 25px;
}

.okrlist .item>table tr td:last-child {
	padding-right: 0;
}

.okrlist .item>table .p2 .btitle {
	font-size: 18px;
	color: #333;
	padding-left: 0;
}

.okrlist .item>table .p2 .btitle .num {
	font-size: 16px;
	color: #fff;
	min-width: 25px;
	height: 25px;
	padding: 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}

.okrlist .item>table .p2 .btitle .num.n1 {
	background-color: #1890FF;
}

.okrlist .item>table .p2 .btitle .num.n2 {
	background-color: #C465E2;
}

.okrlist .item>table .p2 .btitle .num.n3 {
	background-color: #52CC6F;
}

.okrlist .item>table .p2 .btitle .num.n4 {
	background-color: #59DBFF;
}

.okrlist .item>table .p2 .stitle {
	font-size: 15px;
	color: #333;
	padding-left: 35px;
}

.okrlist .item>table .p2 .stitle .num {
	font-size: 14px;
	color: #1890FF;
	height: 20px;
	padding: 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background-color: #EEF4FB;
}

.okrlist .item>table .p2 .a1 {
	font-size: 12px;
	color: #aaa;
}

.okrlist .item>table .p2 .a2 {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
	border-radius: 5px;
}

.okrlist .item>table .p2 .a2.type1 {
	background-color: #EEF4FB;
	color: #1890FF;
}

.okrlist .item>table .p2 .a2.type2 {
	background-color: #FDF5F0;
	color: #FF9959;
}

.okrlist .item>table .p2 .a2.type3 {
	background-color: #F9EDED;
	color: #FF7272;
}

.okrlist .item>.total {
	width: 100%;
}

.okrlist .item>.total .create {
	cursor: pointer;
	height: 25px;
	padding: 0 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F7F8FA;
	color: #888888;
	font-size: 14px;
}

.okrlist .item>.total .r {
	font-size: 16px;
	color: #aaa;
}

.okrlist .item>.total .r .edit {
	color: inherit;
}


.allwidth label {
	width: 100%;
}

.gjjg_list {
	width: 100%;
	background-color: #f8fafd;
	box-sizing: border-box;
	padding: 20px;
}

.gjjg_list .item {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px dashed #ddd;
}

.gjjg_list .item:last-child {
	margin-bottom: 0;
	border-bottom: 0;
}


.qjokr_con {
	width: 100%;
	height: calc(100% - 48px);
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}

.qjokr_con .l {
	width: 280px;
	height: 100%;
	background-color: #fff;
	overflow-y: auto;
	margin-right: 5px;
	padding: 20px;
	box-sizing: border-box;
}

.qjokr_con .l .p1 {
	width: 100%;
	margin-bottom: 15px;
}

.qjokr_con .r {
	width: calc(100% - 285px);
	background-color: #fff;
	padding: 10px;
	box-sizing: border-box;
}

.site-tree-search-value {
	color: #f50;
}


.fls0{
	flex-shrink: 0;
}

.dtview{
	width: 100%;
}
.dtview .p1{
	width: 100%;
	margin-bottom: 15px;
	font-size: 18px;
	color: #0755c3;
}
.dtview .p2{
	width: 100%;
	font-size: 14px;
	columns: #444;
	line-height: 25px;
	margin-bottom: 15px;
}
.dtview .p2 image ,.dtview .p2 .video{
	max-width: 100%;
}
.dtview .p3{
	width: 100%;
	margin-bottom: 25px;
}
.dtview .p4{
	width: 100%;
	margin-bottom: 10px;
	font-size: 16px;
	color: #333;
	font-weight: bold;
}
.dtview .p5{
	width: 100%;
}
.dtview .p5 .item{
	width: 100%;
	padding: 15px;
	box-sizing: border-box;
	background-color: #f8f9fb;
	margin-bottom: 10px;
}
.dtview .p5 .item .a1{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: end;
}
.dtview .p5 .item .a1 .b1 .c1{
	font-size: 14px;
	color: #333;
}
.dtview .p5 .item .a1 .b1 .c2{
	font-size: 14px;
	color: #999;
}
.dtview .p5 .item .a1 .b2{
	font-size: 14px;
	color: #999;
}
.dtview .p5 .item .a1 .b2 .c{
	cursor: pointer;
}
.dtview .p5 .item .a1 .b2 .hover{
	font-size: 14px;
	color: #0755c3;
}
.dtview .p5 .item .a2{
	width: 100%;
	font-size: 14px;
	color: #444;
	font-weight: bold;
	line-height: 25px;
}
.dtview .p6{
	width: 100%;
}
.dtview .p6 .item{
	width: 100%;
	padding: 10px 0;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}
.dtview .p6 .item .a1{
	width: 40px;
	margin-right: 15px;
}
.dtview .p6 .item .a2{
	width: calc(100% - 55px);
}
.dtview .p6 .item .a2 .b1{
	width: 100%;
	font-size: 14px;
	color: #333;
	font-weight: bold;
}
.dtview .p6 .item .a2 .b2{
	font-size: 12px;
	color: #999;
}
.dtview .p7{
	width: 100%;
	background-color: rgb(248,249,251);
	padding: 15px;
	box-sizing: border-box;
	margin-bottom: 15px;
}
.dtview .p7 .a1{
	width: 100%;
	margin-bottom: 10px;
}
.dtview .p7 .a1 .b1{
	font-size: 14px;
	color: #101010;
}
.dtview .p7 .a1 .b2{
	font-size: 14px;
	color: #999;
}
.dtview .p7 .a2{
	width: 100%;
}
.dtview .p8{
	width: 100%;
}
.dtview .p8 .item{
	width: 100%;
	padding: 5px 0;
}
.dtview .p8 .item .a1{
	width: 100%;
	font-size: 16px;
	color: #101010;
	font-weight: bold;
}
.dtview .p8 .item .a2{
	width: 100%;
	font-size: 12px;
	color: #999;
}